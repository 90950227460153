<template>
  <!--//  RPD_hit_n_run_2019 DB APP v0.0.1
      //  Copyright(c) 2019 Blue Cover Six LLC / John Molt
      // BCS grants Unlimited License to Reno Police Department / City of Reno  by contract.-->

  <!-- top -->
  <div>
    <div
      class="buttons d-flex flex-row justify-start ma-4 no-print"
      style="padding: 0; margin: 0"
    >
      <v-btn
        id="print-letter"
        class="no-print mr-2"
        type="button"
        @click="printWindow()"
      >
        Print
      </v-btn>
      <v-btn id="back" class="no-print" type="button" v-on:click="message1">
        Back to Results
      </v-btn>
    </div>
    <!-- v-for="page in pages"
      :key="page.page_num" -->
    <div class="reportback box" style="max-width: 7.5in; margin: auto">
      <div class="letter-header pt-2">
        <div class="lh-flex">
          Reno Police Department<br /><em>"Your Police, Our Community"</em>
        </div>
        <div>
          <img src="../images/gold-badge.png" alt="Reno nevada police Badge" />
        </div>
        <div class="lh-flex-3">
          Kathryn Nance<br />
          <em>Chief of Police</em>
        </div>
      </div>
      <div class="letter-pg1">
        <div class="lh-title"><h4>Traffic Stats Report</h4></div>
        <!-- FIRST CARD -->
        <div id="first-card" class="onecard pa-4">
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">Last modified:</div>
            <p class="data mb-0">005/01/2023</p>
          </div>
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">User:</div>
            <p class="data mb-0">jvonmolt@gmail.com</p>
          </div>
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">Type:</div>
            <p class="data mb-0">Dispatch</p>
          </div>
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">Location Decription:</div>
            <p class="data mb-0">123 4th St</p>
          </div>
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">Details:</div>
            <textarea class="data mb-0">
Wrecked? When did this happen and- Whoa, whoa, okay. Wrecked? When did this happen and- Sam, here's the young man you hit with your car out there. He's alright, thank god. This is uh, this is heavy duty, Doc, this is great. Uh, does it run on regular unleaded gasoline?</textarea
            >
          </div>

          <!-- checkboxes -->
          <div
            class="checkboxesr1 px-0 sm-px-4 d-flex flex-row justify-space-between"
          >
            <!-- Cites -->
            <div class="cbox">
              <v-checkbox
                v-model="stat.cite"
                readonly
                class="mt-0"
                label="Cites"
              ></v-checkbox>
            </div>
            <!-- stops -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="Stops"></v-checkbox>
            </div>
            <!-- CFS -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="CFS"></v-checkbox>
            </div>
            <!-- arrest -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="Arrest"></v-checkbox>
            </div>
          </div>
          <div
            class="checkboxesr2 px-0 sm-px-4 d-flex flex-row justify-space-between pgbreak-after"
          >
            <!-- DUI -->
            <div class="cbox">
              <v-checkbox
                style="color: black"
                readonly
                class="mt-0"
                label="DUI"
              ></v-checkbox>
            </div>
            <!-- accident -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="Accident"></v-checkbox>
            </div>
            <!-- SRO -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="SRO"></v-checkbox>
            </div>
            <!-- MAIT -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="MAIT"></v-checkbox>
            </div>
          </div>
        </div>
        <!-- SECOND CARD -->
        <div id="second-card" class="onecard pa-4">
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">Last modified:</div>
            <p class="data mb-0">005/01/2023</p>
          </div>
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">User:</div>
            <p class="data mb-0">jvonmolt@gmail.com</p>
          </div>
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">Type:</div>
            <p class="data mb-0">Dispatch</p>
          </div>
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">Location:</div>
            <p class="data mb-0">123 4th St</p>
          </div>
          <div class="d-flex flex-row justify-start fullrow mb-1">
            <div class="label font-weight-bold mr-2">Details:</div>
            <textarea class="data mb-0">
Wrecked? When did this happen and- Whoa, whoa, okay. Wrecked? When did this happen and- Sam, here's the young man you hit with your car out there. He's alright, thank god. This is uh, this is heavy duty, Doc, this is great. Uh, does it run on regular unleaded gasoline?</textarea
            >
          </div>

          <!-- checkboxes -->
          <div
            class="checkboxesr1 px-0 sm-px-4 d-flex flex-row justify-space-between"
          >
            <!-- Cites -->
            <div class="cbox">
              <v-checkbox
                v-model="stat.cite"
                readonly
                class="mt-0"
                label="Cites"
              ></v-checkbox>
            </div>
            <!-- stops -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="Stops"></v-checkbox>
            </div>
            <!-- CFS -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="CFS"></v-checkbox>
            </div>
            <!-- arrest -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="Arrest"></v-checkbox>
            </div>
          </div>
          <div
            class="checkboxesr2 px-0 sm-px-4 d-flex flex-row justify-space-between pgbreak-after"
          >
            <!-- DUI -->
            <div class="cbox">
              <v-checkbox
                style="color: black"
                readonly
                class="mt-0"
                label="DUI"
              ></v-checkbox>
            </div>
            <!-- accident -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="Accident"></v-checkbox>
            </div>
            <!-- SRO -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="SRO"></v-checkbox>
            </div>
            <!-- MAIT -->
            <div class="cbox">
              <v-checkbox readonly class="mt-0" label="MAIT"></v-checkbox>
            </div>
          </div>
        </div>
        <div style="width: 7in; text-align: right">
          <small>Page ##</small>
        </div>
        <hr class="no-print" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
html,
body {
  background-color: white !important;
}

/* print media */
@media only print {
  .box {
    width: 7.5in;
    // height: 10in;
  }

  .no-print {
    display: none;
  }
  .pgbreak-after {
    page-break-after: always;
  }
}
/* 0 media q */
@media (min-width: 0px) {
  html,
  body {
    background-color: white !important;
  }

  .v-application--wrap::v-deep {
    max-width: 7.5in !important;
  }

  .onecard {
    height: 500px;
  }

  .onecard,
  .fullrow {
    width: 100%;
  }

  .fullrow .label {
    width: 25%;
  }

  .fullrow .data {
    width: 75%;
  }

  .fullrow textarea {
    height: 150px;
  }

  .checkboxesr1,
  .checkboxesr2 {
    width: 100%;
  }

  .checkboxesr1 .cbox,
  .checkboxesr2 .cbox {
    width: 34%;
    flex-wrap: wrap;
  }

  .cbox .theme--light.v-label--is-disabled::v-deep {
    color: black !important;
    opacity: 1 !important;
  }
  .results-card {
    width: 95%;
  }

  .accident {
    font-size: smaller;
  }

  h4 {
    max-width: 100%;
    margin-bottom: 0;
    line-height: 1.5rem;
    font-size: 1.2rem;
  }

  h6 {
    line-height: 1rem;
  }

  #suspect_letter .box {
    padding: 10px;
    background-color: white;
    margin: 0 auto;
    font-size: 3rem;
    color: black;
    width: 7.5in;
    height: 10in;
  }

  .viewtitle {
    margin-top: 43px;
  }

  .letter-pg1 {
    width: 100%;
  }

  .letter-header {
    width: 100%;
    font-size: 14px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 25px;
  }

  .letter-header img {
    width: 85px;
  }

  .letter-header .lh-flex,
  .letter-header .lh-flex-3 {
    width: 33%;
  }

  .lh-flex-3 {
    text-align: right;
  }

  .lh-title {
    width: 100%;
    text-align: center;
  }

  .lh-title h4 {
    font-weight: bold;
  }

  .instructions {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    padding-bottom: 0.25in;
  }

  .info-row {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
  }

  .move-rt {
    margin-right: 0.28in;
  }

  .wraptext {
    margin-top: 0.25in;
  }

  .label1-5 {
    width: 1.5in;
    text-align: right;
  }

  .line75 {
    width: 0.75in;
    border-bottom: 1px solid black;
    margin-left: 10px;
    margin-right: 10px;
  }

  .line1-25 {
    width: 1.25in;
    border-bottom: 1px solid black;
    margin-left: 10px;
    margin-right: 10px;
  }

  .line3-5 {
    width: 3.5in;
    border-bottom: 1px solid black;
    margin-left: 10px;
    margin-right: 10px;
  }

  .line5-87 {
    width: 5.87in;
    border-bottom: 1px solid black;
    margin-left: 10px;
    margin-right: 10px;
  }

  .bold,
  .form h6 {
    font-weight: bold;
  }

  .blue,
  .form h6 {
    color: #0063ff;
  }

  .italic {
    font-style: italic;
  }

  .normal h6 {
    font-style: normal;
  }

  /*   .accident .v-application--is-ltr::v-deep .v-chip .v-avatar--left {
    margin-right: 0 !important;
  } */
}

/* 768 media q */
@media (min-width: 768px) {
  .results-card {
    max-width: 100%;
  }

  .checkboxesr1 .cbox,
  .checkboxesr2 .cbox {
    width: 25%;
    flex-wrap: nowrap;
  }

  .onecard {
    height: 400px;
  }
}

/* ipad lansdscape */
@media (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* 1025 media q */
@media (min-width: 1025px) {
}

/* 2048 media q */
@media (min-width: 2048px) {
}

element.style {
  left: 0px;
  right: auto;
  position: relative;
  color: black !important;
}

Label {
  color: rgba(0, 0, 0, 1) !important;
}
</style>

<script>
import { bus_common } from "../mixins/bus_common";
import searchResults from "./searchResults.vue";
/* eslint-disable */
export default {
  name: "report",
  components: {
    searchResults,
  },
  mixins: [bus_common],

  data() {
    return {
      group_size: 9,
      total_loaded: 0,
      pages: [], //an int[] for page count
      lst_stats: [],
      stat: {
        cite: true,
      },
    };
  },
  watch: {
    "$store.state.gbl_report_data": {
      handler() {
        // if ($store.state.gbl_report_data.length > 0) {
        //   this.lst_stats = this.mno(this.$store.state.gbl_report_data);
        // }
        // this.lst_stats = this.mno(this.lst_search_results);
        // for (let index = 0; index < this.lst_stats.length; index++) {
        //   var stat = this.lst_stats[index];
        //   stat.s_date = this.$moment(stat.s_date).format("MM/DD/YYYY");
        //   stat.s_CreateDate = this.$moment(stat.s_CreateDate).format(
        //     "MM/DD/YYYY"
        //   );
        // }
        //this.$moment(value, 'YYYY-MM-DD', true);
      },
    },
  },
  methods: {
    get_recent_stats() {
      var self = this;

      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.group_size = this.group_size;
      formIn.total_loaded = this.total_loaded;

      formIn.apiname = "get_recent_stats2";
      var self = this;
      this.$store
        .dispatch("get_recent_stats2", formIn)
        .then((data) => {
          this.show_loader(false);
          self.lst_stats = self.mno(data);
          this.bReady_to_update = true;
          this.$store.commit("set_gbl_report_data", this.lst_stats);
          this.init();
        })
        .catch((msg) => {
          this.show_loader(false);
          this.swall_it("Error", msg, "error", null, null);
        });
    },
    printWindow: function () {
      window.print();
    },
    message1() {
      alert("This button will go to back to search results");
    },
    message2() {
      alert("This button takes you back to add/edit.");
    },
    message3() {
      alert("This button will delete record");
    },
    message4() {
      alert("This button will sent to records");
    },
    message5() {
      alert("This button will remove from results");
    },

    init() {
      if (
        this.$store.state.gbl_report_data == null ||
        this.$store.state.gbl_report_data.length == 0
      ) {
        this.swall_it(
          "Report Status",
          "No data sent to report.",
          "error",
          null,
          null
        );
        this.$router.push("/");
        return true;
      } else {
        this.lst_stats = this.mno(this.$store.state.gbl_report_data);
        // this.lst_stats = this.lst_stats.slice(0, 2);
        for (let index = 0; index < this.lst_stats.length; index++) {
          var stat = this.lst_stats[index];
          stat.s_date = this.$moment(stat.s_date).format("MM/DD/YYYY");
          stat.s_CreateDate = this.$moment(stat.s_CreateDate).format(
            "MM/DD/YYYY"
          );
        }
        var icnt = 0;
        for (let indexz = 0; indexz < this.lst_stats.length; indexz++) {
          icnt += 1;
          if (icnt + 1 != this.lst_stats.length) {
            this.pages.push({
              page_num: icnt,
              stat1: this.lst_stats[indexz],
              stat2: this.lst_stats[indexz + 1],
            });
          } else {
            this.pages.push({
              page_num: icnt,
              stat1: this.lst_stats[indexz],
              stat2: null,
            });
          }
        }
      }
    },
  },

  mounted() {
    //  this.get_recent_stats();
    this.$store.commit("setLoader_visible", false);
    this.$store.commit("setBrandbar_show", false);
    document.getElementsByTagName("BODY")[0].classList.add("whiteness");
    this.$nextTick(() => {
      this.init();
    });
  },
  beforeDestroy() {
    this.$store.commit("setBrandbar_show", true);
    document.getElementsByTagName("BODY")[0].classList.remove("whiteness");
  },
};
</script>
